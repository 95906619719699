import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
import versions from '../../../gen-src/versions.json';
export const pageTitle = "Scala integration";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "scala-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#scala-integration",
        "aria-label": "scala integration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Scala integration`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#armeria-scala"
          }}>{`armeria-scala`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#conversion-between-java-completionstage-and-scala-future"
            }}>{`Conversion between Java CompletionStage and Scala Future`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#executioncontextssamethread"
            }}>{`ExecutionContexts.sameThread`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#context-aware-executioncontext"
            }}>{`Context-aware ExecutionContext`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#collection-converters"
            }}>{`Collection converters`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#implicit-conversion-between-java-duration-and-scala-finiteduration"
            }}>{`Implicit conversion between Java Duration and Scala FiniteDuration`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`As a matter of fact, there are three distinct ways to integrate Armeria and Scala:`}</p>
    <ul>
      <li parentName="ul">{`by using the `}<inlineCode parentName="li">{`armeria-scala`}</inlineCode>{` library;`}</li>
      <li parentName="ul">{`by using the `}<a parentName="li" {...{
          "href": "https://github.com/http4s/http4s-armeria"
        }}><inlineCode parentName="a">{`http4s-armeria`}</inlineCode></a>{` library;`}</li>
      <li parentName="ul">{`or just by using Armeria directly in your Scala projects. In that case,
the supplied API would be fully in `}<inlineCode parentName="li">{`Java`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`If you're searching for the most Scala-way experience of using Armeria,
take a look at `}<inlineCode parentName="p">{`armeria-scala`}</inlineCode>{` and `}<inlineCode parentName="p">{`http4s-armeria`}</inlineCode>{`. These two libraries significantly
differentiate from each other.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`armeria-scala`}</inlineCode>{` is built on top of the Scala Standard Library, and asynchronous computations
are provided by the `}<inlineCode parentName="li">{`scala.concurrent`}</inlineCode>{` library. By picking this library, you will work with
HTTP through the Armeria API and get the handy functionality to bridge Java API to Scala.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/http4s/http4s-armeria"
        }}><inlineCode parentName="a">{`http4s-armeria`}</inlineCode></a>{`, in turn, is built on top
of the Functional Programming Libraries — `}<a parentName="li" {...{
          "href": "https://http4s.org/"
        }}><inlineCode parentName="a">{`http4s`}</inlineCode></a>{` exposes typeful,
functional, streaming HTTP API, and `}<a parentName="li" {...{
          "href": "https://typelevel.org/cats-effect/"
        }}><inlineCode parentName="a">{`Cats-Effect`}</inlineCode></a>{`
accords a reach functionality for the asynchronous and concurrent computations.
This means that Armeria is used as the underlying Backend for the Server and Client.`}</li>
    </ul>
    <p>{`Since the `}<inlineCode parentName="p">{`http4s-armeria`}</inlineCode>{` is a third-party project,
its documentation is not listed here, so discover it on their resources. Below on this page
is the documentation of the `}<inlineCode parentName="p">{`armeria-scala`}</inlineCode>{` library. Choose the most fittable way to integrate
Armeria and Scala according to your demands and needs!`}</p>
    <h2 {...{
      "id": "armeria-scala",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#armeria-scala",
        "aria-label": "armeria scala permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`armeria-scala`}</inlineCode></h2>
    <Tip mdxType="Tip">
      <p>{`The `}<inlineCode parentName="p">{`armeria-scala`}</inlineCode>{` module is still experimental. We may make a breaking change if we find a better way
to bridge Armeria to Scala.`}</p>
    </Tip>
    <p>{`The `}<inlineCode parentName="p">{`com.linecorp.armeria.scala`}</inlineCode>{` package provides various useful extension methods and implicit conversions
for an Armeria application written in `}<a parentName="p" {...{
        "href": "https://www.scala-lang.org/"
      }}>{`Scala`}</a>{`. To enable it, you first need
the `}<inlineCode parentName="p">{`armeria-scala_2.12`}</inlineCode>{` or `}<inlineCode parentName="p">{`armeria-scala_2.13`}</inlineCode>{` dependency:`}</p>
    <CodeBlock language="groovy" filename="build.sbt" mdxType="CodeBlock">{`
libraryDependencies += "com.linecorp.armeria" %% "armeria-scala" % "${versions['com.linecorp.armeria:armeria-bom']}"
`}</CodeBlock>
    <p>{`and then import `}<inlineCode parentName="p">{`com.linecorp.armeria.scala.implicits._`}</inlineCode>{` in your Scala code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.scala.implicits._
`}</code></pre>
    <h3 {...{
      "id": "conversion-between-java-completionstage-and-scala-future",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#conversion-between-java-completionstage-and-scala-future",
        "aria-label": "conversion between java completionstage and scala future permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conversion between Java `}<inlineCode parentName="h3">{`CompletionStage`}</inlineCode>{` and Scala `}<inlineCode parentName="h3">{`Future`}</inlineCode></h3>
    <p>{`You can convert a Java `}<inlineCode parentName="p">{`CompletionStage`}</inlineCode>{` to a Scala `}<inlineCode parentName="p">{`Future`}</inlineCode>{` using the `}<inlineCode parentName="p">{`toScala`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.common.HttpResponse
import com.linecorp.armeria.scala.implicits._
import com.linecorp.armeria.server.Server

import java.util.concurrent.CompletableFuture
import scala.concurrent.Future

val server =
  Server
    .builder()
    .service("/", (ctx, req) => HttpResponse.of(200))
    .build()

val javaFuture: CompletableFuture[Void] = server.start()
val scalaFuture: Future[Unit] = javaFuture.toScala // 👈
`}</code></pre>
    <p>{`You can also convert a Scala `}<inlineCode parentName="p">{`Future`}</inlineCode>{` to a Java `}<inlineCode parentName="p">{`CompletionStage`}</inlineCode>{` using the `}<inlineCode parentName="p">{`toJava`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import java.util.concurrent.CompletionStage
val javaFuture: CompletionStage[Void] = scalaFuture.toJava // 👈
`}</code></pre>
    <p>{`You'll also find the extension method `}<inlineCode parentName="p">{`toHttpResponse`}</inlineCode>{` added to `}<inlineCode parentName="p">{`Future[HttpResponse]`}</inlineCode>{` and
`}<inlineCode parentName="p">{`CompletionStage[HttpResponse]`}</inlineCode>{` when converting an asynchronous result into an `}<a parentName="p" {...{
        "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
      }}>{`type://HttpResponse`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.common.MediaType
import com.linecorp.armeria.scala.implicits._

val future: Future[String] = ...
val response =
  future
    .map { value => HttpResponse.of(MediaType.PLAIN_TEXT_UTF_8, value) }
    .toHttpResponse // 👈
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`You might wonder why we implemented our own extension methods rather then letting you use
`}<inlineCode parentName="p">{`FutureConverters`}</inlineCode>{` in `}<a parentName="p" {...{
          "href": "https://github.com/scala/scala-java8-compat"
        }}><inlineCode parentName="a">{`scala-java8-compat`}</inlineCode></a>{`.
It is because `}<inlineCode parentName="p">{`armeria-scala`}</inlineCode>{` provides richer and more natural conversions, such as:`}</p>
      <ul>
        <li parentName="ul">{`Conversion to `}<a parentName="li" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a></li>
        <li parentName="ul">{`Conversion from `}<inlineCode parentName="li">{`CompletionStage[Void]`}</inlineCode>{` to `}<inlineCode parentName="li">{`Future[Unit]`}</inlineCode></li>
        <li parentName="ul">{`Conversion from `}<inlineCode parentName="li">{`Future[Unit]`}</inlineCode>{` to `}<inlineCode parentName="li">{`CompletionStage[Void]`}</inlineCode></li>
      </ul>
    </Tip>
    <h3 {...{
      "id": "executioncontextssamethread",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#executioncontextssamethread",
        "aria-label": "executioncontextssamethread permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h3">{`ExecutionContexts.sameThread`}</inlineCode></h3>
    <p>{`In an asynchronous system that the entire application logic runs on event loops, it is often useful
in terms of performance to invoke the callbacks attached to a `}<inlineCode parentName="p">{`Future`}</inlineCode>{` directly rather than submitting
the callbacks to another `}<inlineCode parentName="p">{`ExecutionContext`}</inlineCode>{`. You can use `}<inlineCode parentName="p">{`ExecutionContexts.sameThread`}</inlineCode>{` in such a case:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.common.HttpRequest
import com.linecorp.armeria.scala.ExecutionContexts.sameThread
import com.linecorp.armeria.scala.implicits._
import com.linecorp.armeria.server.HttpService
import com.linecorp.armeria.server.ServiceRequestContext

import scala.concurrent.ExecutionContext

class MyHttpService extends HttpService {
  override def serve(ctx: ServiceRequestContext, req: HttpRequest): HttpResponse = {
    implicit val ec: ExecutionContext = sameThread // 👈

    // Perform some asynchronous operation.
    val future: Future[String] = ...

    // Convert the result to a response.
    future
      .map { value =>
        HttpResponse.of(MediaType.PLAIN_TEXT_UTF_8, value)
      }
      .toHttpResponse
  }
}
`}</code></pre>
    <h3 {...{
      "id": "context-aware-executioncontext",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#context-aware-executioncontext",
        "aria-label": "context aware executioncontext permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Context-aware `}<inlineCode parentName="h3">{`ExecutionContext`}</inlineCode></h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`eventLoopExecutionContext`}</inlineCode>{` extension method in `}<a parentName="p" {...{
        "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
      }}>{`type://RequestContext`}</a>{` to specify a Scala
`}<inlineCode parentName="p">{`ExecutionContext`}</inlineCode>{` that submits all tasks to the current context's event loop thread:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.scala.implicits._

val ctx = ServiceRequestContext.current
implicit val ec: ExecutionContext = ctx.eventLoopExecutionContext // 👈
Future {
  // Do some non-blocking job here.
}
`}</code></pre>
    <p>{`For long-running tasks running on the server side, you can use `}<inlineCode parentName="p">{`blockingTaskExecutionContext`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.scala.implicits._

val ctx = ServiceRequestContext.current
implicit val ec: ExecutionContext = ctx.blockingTaskExecutionContext // 👈
Future {
  Thread.sleep(1000)
}
`}</code></pre>
    <h3 {...{
      "id": "collection-converters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#collection-converters",
        "aria-label": "collection converters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Collection converters`}</h3>
    <p><inlineCode parentName="p">{`com.linecorp.armeria.scala.implicits._`}</inlineCode>{` will add `}<inlineCode parentName="p">{`toScala`}</inlineCode>{` and `}<inlineCode parentName="p">{`toJava`}</inlineCode>{` conversion extension methods to
Java collections and Scala collections respectively. It means you don't need to import
`}<inlineCode parentName="p">{`scala.jdk.CollectionConverters._`}</inlineCode>{` if you imported Armeria's implicits.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.scala.implicits._
import com.linecorp.armeria.server.Server

val server: Server = ...
val scalaList = server.activePorts.toScala // 👈
`}</code></pre>
    <h3 {...{
      "id": "implicit-conversion-between-java-duration-and-scala-finiteduration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#implicit-conversion-between-java-duration-and-scala-finiteduration",
        "aria-label": "implicit conversion between java duration and scala finiteduration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implicit conversion between Java `}<inlineCode parentName="h3">{`Duration`}</inlineCode>{` and Scala `}<inlineCode parentName="h3">{`FiniteDuration`}</inlineCode></h3>
    <p>{`A Scala `}<inlineCode parentName="p">{`FiniteDuration`}</inlineCode>{` is implicitly converted into a Java `}<inlineCode parentName="p">{`Duration`}</inlineCode>{` and vice versa for your convenience:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.scala.implicits._
import scala.concurrent.duration._

Server
  .builder()
  .requestTimeout(5.seconds) // 👈
  ...
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      